@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~react-quill/dist/quill.snow.css";


/* styles.css */

.modal {
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  border-radius: 8px;
  max-width: 1250px;
  width: 100%;
  max-height: 80vh;
  overflow-y: auto;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
}
